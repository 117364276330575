<template>
    <div class="wrap">
        <div class="container">
            <div class="nav_bar">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/recruit' }">我的招聘</el-breadcrumb-item>
                    <el-breadcrumb-item>{{ $route.query.edit ? '修改公司信息' : '完善公司信息'}}</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
            <div class="form_area">
                <el-form :model="companyInfo" :rules="rules" ref="companyInfo" label-width="100px" class="form">
                    <el-form-item label="企业名称" prop="companyName">
                        <el-input v-model="companyInfo.companyName" placeholder="请填写企业名称"></el-input>
                    </el-form-item>
                    <el-form-item label="企业简称" prop="companyNickName">
                        <el-input v-model="companyInfo.companyNickName" placeholder="请填写企业简称"></el-input>
                    </el-form-item>
                    <el-form-item label="企业法人" prop="legalPerson">
                        <el-input v-model="companyInfo.legalPerson" placeholder="请填写企业法人"></el-input>
                    </el-form-item>
                    <el-form-item label="招聘HR" prop="hr">
                        <el-input v-model="companyInfo.hr" placeholder="请填写招聘HR"></el-input>
                    </el-form-item>
                    <el-form-item label="企业LOGO" prop="companyLogo">
                        <el-upload class="uploader" action="#" :show-file-list="false" :auto-upload="false"
                            :on-change="(file) => upload(file, 'companyLogo')">
                            <img class="upload_img" v-if="companyInfo.companyLogo" :src="companyInfo.companyLogo" />
                            <i v-else class="el-icon-plus"></i>
                        </el-upload>
                    </el-form-item>
                    <el-form-item label="营业执照" prop="businessLicense">
                        <el-upload class="uploader" action="#" :show-file-list="false" :auto-upload="false"
                            :on-change="(file) => upload(file, 'businessLicense')">
                            <img class="upload_img" v-if="companyInfo.businessLicense" :src="companyInfo.businessLicense" />
                            <i v-else class="el-icon-plus"></i>
                        </el-upload>
                    </el-form-item>
                    <el-form-item label="企业规模" prop="peopleCount">
                        <el-select v-model="companyInfo.peopleCount" placeholder="请选择企业规模">
                            <el-option v-for="item in peopleCountList" :key="item" :label="item" :value="item">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="企业地址" prop="address">
                        <el-input type="textarea" v-model="companyInfo.address" resize="none" :autosize="{ minRows: 3 }"
                            placeholder="请填写企业地址"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button class="submit_btn" type="primary" @click="submitForm('companyInfo')">提交</el-button>
                        <el-button class="reset_btn" @click="resetForm('companyInfo')">重置</el-button>
                    </el-form-item>
                </el-form>

            </div>
        </div>
    </div>
</template>

<script>
    import {
        getCompanyInfo,
        editCompanyInfo
    } from '@/api/recruit'
    import upload from "@/utils/uploadMethod";
    export default {
        name: 'companyData',
        data() {
            return {
                companyInfo: {
                    companyName: '',
                    companyLogo: '',
                    legalPerson: '',
                    companyNickName: '',
                    hr: '',
                    businessLicense: '',
                    peopleCount: '',
                    address: ''
                },
                rules: {
                    companyName: {
                        required: true,
                        message: "请填写企业名称",
                        trigger: "blur",
                    },
                    companyNickName: {
                        required: true,
                        message: "请填写企业简称",
                        trigger: "blur",
                    },
                    legalPerson: {
                        required: true,
                        message: "请填写企业法人",
                        trigger: "blur",
                    },
                    hr: {
                        required: true,
                        message: "请填写招聘HR",
                        trigger: "blur",
                    },
                    companyLogo: {
                        required: true,
                        message: "请上传企业LOGO",
                        trigger: "blur",
                    },
                    businessLicense: {
                        required: true,
                        message: "上传营业执照",
                        trigger: "blur",
                    },
                    peopleCount: {
                        required: true,
                        message: "请选择企业规模",
                        trigger: "blur",
                    },
                    address: {
                        required: true,
                        message: "请填写企业地址",
                        trigger: "blur",
                    },
                },
                peopleCountList: ['10人以下', '10~50人', '50~100人', '100~200人', '200人以上']
            }
        },
        created() {
            if (this.$route.query.edit) this.getInfo();
        },
        methods: {
            getInfo() {
                this.$loading.show();
                getCompanyInfo().then(res => {
                    this.$loading.hide();
                    if(res.code == 200) {
                        this.companyInfo = res.data;
                    } else {
                        this.$message.error(res.msg);
                    }
                })
            },
            //上传图片
            async upload(file, str) {
                let option = {
                    fileType: ["image/jpeg", "image/png", "image/bmp"],
                    isVerificationType: true,
                    isVerificationSize: true,
                    fileSize: 5,
                };
                const fileUrl = await upload(file, option);
                this.companyInfo[str] = fileUrl;
            },
            // 提交
            submitForm(formName) {
                console.log(this.companyInfo);
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        editCompanyInfo(this.companyInfo).then(res => {
                            if (res.code == 200) {
                                this.$message.success('修改成功');
                                setTimeout(() => {
                                    this.$router.go(-1);
                                }, 500);
                            } else {
                                this.$message.error(res.msg);
                            }
                        })
                    } else {
                        console.log("error submit!!");
                        return false;
                    }
                });
            },
            // 重置
            resetForm(formName) {
                this.$refs[formName].resetFields();
            },
        }

    }
</script>

<style lang="less" scoped>
    .wrap {
        min-height: 693px;
        background: #f3f3f3;

        .nav_bar {
            padding: 30px;
            margin: 30px 0;
            background-color: #fff;
            border-radius: 4px;
        }

        .form_area {
            margin: 0 30px;
            padding: 70px 280px 100px;
            border-radius: 4px;
            background-color: #fff;

            ::v-deep.form {

                .el-input__inner,
                .el-textarea__inner {
                    font-family: inherit;
                }

                .el-select {
                    width: 100%;
                }

                .uploader {
                    width: 178px;
                    height: 178px;
                    border: 1px dashed #d9d9d9;
                    border-radius: 6px;
                    cursor: pointer;
                    position: relative;
                    overflow: hidden;

                    &:hover {
                        border-color: #409EFF;
                    }

                    .el-icon-plus {
                        font-size: 28px;
                        color: #8c939d;
                        width: 178px;
                        height: 178px;
                        line-height: 178px;
                        text-align: center;
                    }

                    .upload_img {
                        width: 178px;
                        height: 178px;
                        display: block;
                    }
                }

                .submit_btn,
                .reset_btn {
                    width: 97px;
                    height: 40px;

                    &.submit_btn {
                        background: #1890ff;
                        border-color: #1890ff;
                    }
                }
            }
        }
    }
</style>